import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MastHead from "../components/masthead"
import ArticleList from "../components/masonry-list"

import { Container, Row, Col } from "reactstrap"


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      preamble: file(name: {eq: "home"}) {
        id
        childMdx {
          frontmatter {
            title
            subtitle
            type
            duration 
            hero {
              childImageSharp {
                fixed {
                  src
                }
              }
            }         
          }
          body
        }
      }
      thinking: allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {category: {in: ["thinking","tech","triathlon"]}}}) {
        nodes {
          frontmatter {
            title
            subtitle
            type
            duration
            date(formatString: "MMM D, YYYY")          
            hero {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          timeToRead
          excerpt(pruneLength: 160)
        }
      }      
      dadventures: allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {category: {in: ["dadventure"]}}}) {
        nodes {
          frontmatter {
            title
            subtitle
            type
            duration
            date(formatString: "MMM D, YYYY")          
            hero {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          timeToRead
          excerpt(pruneLength: 160)
        }
      }
    }
  `) 
  
  const Thinking = ArticleList(data.thinking.nodes)
  const Dadventures = ArticleList(data.dadventures.nodes)

  const {title, subtitle, hero} = data.preamble.childMdx.frontmatter
  const body = data.preamble.childMdx.body

  const shortcodes = {Thinking, Dadventures}
  
  return (
    <Layout>
      <SEO title="Home" image={hero.childImageSharp.fixed.src}/>
      <MastHead title={title} subtitle={subtitle} image={hero.childImageSharp.fixed.src}/>
      <Container>
        <Row>
          <Col lg="12" md="12" className="mx-auto">
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>              
        </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
